.course__title {
  position: relative;
  z-index: 0;

  display: flex;

  &::before {
    content: "";

    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1;

    height: 1px;
    background-color: var(--glow);
  }
}

.course__title__bg {
  position: relative;

  &::before {
    content: "";
    z-index: -1;

    position: absolute;
    top: 0;
    left: -0.5rem;
    bottom: 0;
    right: -0.5rem;

    background-color: var(--card);
  }
}

.course__bar {
  transition: width 0.2s ease-out;
}

.course__option--focused {
  width: calc(100% + 1rem);
  margin: calc(0.5rem - 1px) calc(-0.5rem - 1px) calc(-0.5rem - 1px);

  &:focus-within {
    border-color: var(--primary);
  }
}

.course__option__price-input {
  display: flex;
  flex-grow: 0;
  min-width: 1rem;
  line-height: 1rem;

  border-radius: 9999px;

  &::after {
    content: "";
    position: absolute;

    left: -0.25rem;
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    border-radius: 9999px;
  }

  &:hover::after {
    background-color: var(--glow);
  }

  &:focus-within {
    border: 1px solid var(--primary);
    padding: 0 0.25rem;

    &::after {
      display: none;
    }
  }
}

.course__option__price-input--filled {
  border: 1px solid var(--accent);
  padding: 0 0.25rem;

  &::after {
    display: none;
  }
}

.course__option__price-input__label {
  width: 1rem;
  padding-left: 0.125rem;
  white-space: nowrap;
  overflow: hidden;

  font-weight: bold;
}

.course__option__price-input:focus-within .course__option__price-input__label,
.course__option__price-input--filled .course__option__price-input__label {
  width: auto;

  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: normal;
}

.course__option__price-input__input {
  width: 0;
  padding: 0;
  border: none;

  opacity: 0;
}
