@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 2em;
  height: 2em;
  border: 0.25em solid;
  border-right-color: transparent !important;
  border-radius: 100%;

  animation: rotate 1s linear infinite;
}

.loader--sm {
  width: 1em;
  height: 1em;
  border-width: 0.125em;
}

.clickable {
  cursor: pointer;
  border-radius: 1px;
  &:hover {
    background-color: var(--glow);
    box-shadow: 0 0 0 0.5rem var(--glow);
  }
}

.focusable {
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--primary);
  }
}
