.participation-actions__position {
  position: sticky;
  bottom: calc(env(safe-area-inset-bottom) + 1rem);
  z-index: 2;
}

.participation-actions__actions {
  width: calc(100% + 1rem);
  margin: 0 -0.5rem -0.5rem;
  min-height: 0.5rem;

  white-space: nowrap;

  overflow-x: auto;

  & > div {
    display: inline-flex;
    padding: 0.5rem 0;
    margin: auto;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 0.5rem;
      height: 1rem;
      flex-shrink: 0;
    }
  }
}
