.DayPicker-NavBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: -2rem;
}

.DayPicker-Caption {
  padding: 0.25rem 0;
  margin: 0 2rem;
  text-align: center;
}

.DayPicker-NavButton--prev,
.DayPicker-NavButton--next,
.DayPicker-Weekday,
.DayPicker-Day {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  border: none;
  border-radius: 100%;
}

.DayPicker-NavButton--prev::after {
  content: "\25C0";
}

.DayPicker-NavButton--next::after {
  content: "\25B6";
}

.DayPicker-NavButton--prev,
.DayPicker-NavButton--next,
.DayPicker-Day {
  cursor: pointer;
  &:hover {
    background-color: var(--glow);
  }
}

.DayPicker-Weekday abbr {
  font-size: 0.75rem;
  line-height: 1rem;
  text-decoration: none;
}

.DayPicker-WeekdaysRow,
.DayPicker-Week {
  display: flex;
}

.DayPicker-Day--today {
  color: var(--primary);
}

.DayPicker-Day--selected {
  background-color: var(--primary);
  color: var(--primary_contrast);

  &:hover {
    background-color: var(--primary_shade);
  }
}
