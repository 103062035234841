@import "./constants";

$size-properties: (
  "w": "width",
  "min-w": "min-width",
  "max-w": "max-width",
  "h": "height",
  "min-h": "min-height",
  "max-h": "max-height",
);

@include responsive {
  @each $prefix, $prop in $size-properties {
    @each $label, $value in $fixed-sizes {
      .#{$bp}#{$prefix}-#{$label} {
        #{$prop}: #{$value};
      }
    }
  }
}

// Partial width
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-2\/4 {
  width: 50%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-4\/5 {
  width: 80%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-2\/6 {
  width: 33.333333%;
}
.w-3\/6 {
  width: 50%;
}
.w-4\/6 {
  width: 66.666667%;
}
.w-5\/6 {
  width: 83.333333%;
}
.w-1\/12 {
  width: 8.333333%;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-3\/12 {
  width: 25%;
}
.w-4\/12 {
  width: 33.333333%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-6\/12 {
  width: 50%;
}
.w-7\/12 {
  width: 58.333333%;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-9\/12 {
  width: 75%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-11\/12 {
  width: 91.666667%;
}

// Max-Width
.max-w-xs {
  max-width: 20rem;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-5xl {
  max-width: 64rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-screen-sm {
  max-width: 640px;
}
.max-w-screen-md {
  max-width: 768px;
}
.max-w-screen-lg {
  max-width: 1024px;
}
.max-w-screen-xl {
  max-width: 1280px;
}
.max-w-none {
  max-width: none;
}
