.group-actions__container {
  border-radius: 100%;
  box-shadow: 0 0 4rem 16rem transparent;

  transition: box-shadow 0.3s ease-out;
  will-change: box-shadow;
}

.group-actions__container--expanded {
  box-shadow: 0 0 4rem 16rem rgba(var(--background_rgb), 0.8);
}

.group-actions__button__position {
  position: absolute;
  top: 0.25rem;
  right: 1.25rem;

  pointer-events: none;
  opacity: 0;
  transform: translate(0, 1rem);

  transition-property: opacity, transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  will-change: opacity, transform;
}

.group-actions__button__position--expanded {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.group-actions__button__label {
  position: absolute;
  right: 3.5rem;
  top: 50%;

  white-space: nowrap;

  transform: translate(0, -50%);
  opacity: 0;

  transition: opacity 0.2s 0.3s ease-out;
  will-change: opacity;
}

.group-actions__button__position--expanded .group-actions__button__label {
  opacity: 1;
}
