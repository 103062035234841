.modal__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;

  backdrop-filter: blur(0.5rem);
  background-color: #0004;
}
