@import "./constants";

@include responsive {
  .#{$bp}hidden {
    display: none;
  }
  .#{$bp}block {
    display: block;
  }
  .#{$bp}flow-root {
    display: flow-root;
  }
  .#{$bp}inline-block {
    display: inline-block;
  }
  .#{$bp}inline {
    display: inline;
  }
  .#{$bp}flex {
    display: flex;
  }
  .#{$bp}inline-flex {
    display: inline-flex;
  }
  .#{$bp}grid {
    display: grid;
  }
  .#{$bp}inline-grid {
    display: inline-grid;
  }
  .#{$bp}table {
    display: table;
  }
  .#{$bp}table-caption {
    display: table-caption;
  }
  .#{$bp}table-cell {
    display: table-cell;
  }
  .#{$bp}table-column {
    display: table-column;
  }
  .#{$bp}table-column-group {
    display: table-column-group;
  }
  .#{$bp}table-footer-group {
    display: table-footer-group;
  }
  .#{$bp}table-header-group {
    display: table-header-group;
  }
  .#{$bp}table-row-group {
    display: table-row-group;
  }
  .#{$bp}table-row {
    display: table-row;
  }
}
