.icon {
  vertical-align: text-top;

  fill: currentColor;
  stroke: currentColor;
  stroke-linejoin: round;

  flex-shrink: 0;
}

$stroke-widths: 0, 2, 3, 6, 8, 9;

@each $w in $stroke-widths {
  .stroke-#{$w} {
    stroke-width: #{$w};
  }
}
