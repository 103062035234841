.dropdown {
  cursor: pointer;
  position: relative;
}

.dropdown--bare {
  &::before {
    content: "";
    border-radius: 0.75rem;
    position: absolute;
    top: 0;
    left: -0.5rem;
    bottom: 0;
    right: -0.5rem;
  }

  &:hover::before {
    background-color: var(--glow);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    outline: none;
    z-index: 1;

    &::before {
      border: 1px solid var(--primary);
    }
  }
}

.dropdown--bordered {
  border-radius: var(--border-radius);
  background-color: var(--card);

  border: 1px solid var(--accent);

  &:hover {
    background-color: var(--glow);
  }

  &:focus {
    border-color: var(--primary);
  }
}

.dropdown--disabled {
  pointer-events: none;
  background-color: var(--glow);
}

.dropdown--xl {
  padding: 1.25rem 2rem;
}
.dropdown--lg {
  padding: 1rem 1.5rem;
}
.dropdown--md {
  padding: 0.5rem 1rem;
}
.dropdown--sm {
  padding: 0.25rem 0.75rem;
}
.dropdown--xs {
  padding: 0 0.5rem;
}
