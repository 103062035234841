.group-graphic__container {
  position: relative;
  z-index: 2;

  margin: auto;

  transform-origin: top;
}

.group-graphic__image {
  position: relative;
  z-index: 1;

  border-radius: 100%;
  background-position: center;
  background-size: cover;
}

.group-graphic__member {
  position: absolute;
  top: 50%;
  left: 50%;

  border-radius: 100%;
  box-shadow: var(--box-shadow-xs);

  transform: translate(-50%, -50%) scale(0);

  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

$transforms: 0 -1.7 -1.2 1.1, 1 -1.2 1.5 0.85, 2 -3.4 0.8 0.75;

@each $transform in $transforms {
  .group-graphic__member--#{nth($transform, 1) * 2} {
    transform: translate(#{nth($transform, 2)}rem, #{nth($transform, 3)}rem)
      translate(-50%, -50%)
      scale(#{nth($transform, 4)});
    transition-delay: #{nth($transform, 1) * 0.2}s;
  }

  .group-graphic__member--#{nth($transform, 1) * 2 + 1} {
    transform: translate(
        #{-1 * nth($transform, 2)}rem,
        #{-1 * nth($transform, 3)}rem
      )
      translate(-50%, -50%)
      scale(#{nth($transform, 4)});
    transition-delay: #{nth($transform, 1) * 0.2 + 0.1}s;
  }

  .group-graphic__member--side.group-graphic__member--#{nth($transform, 1)
    *
    2} {
    transform: translate(#{nth($transform, 2) - 3}rem, #{nth($transform, 3)}rem)
      translate(-50%, -50%)
      scale(#{nth($transform, 4)});
    transition-delay: #{nth($transform, 1) * 0.2}s;
  }

  .group-graphic__member--side.group-graphic__member--#{nth($transform, 1)
    *
    2
    + 1} {
    transform: translate(
        #{-1 * nth($transform, 2) + 3}rem,
        #{-1 * nth($transform, 3)}rem
      )
      translate(-50%, -50%)
      scale(#{nth($transform, 4)});
    transition-delay: #{nth($transform, 1) * 0.2 + 0.1}s;
  }
}
