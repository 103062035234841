*,
*::before,
*::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: none;
}

html,
body,
#root {
  height: 100%;
}

html {
  font-family: var(--copy-font);
  line-height: 1.5;
}

body {
  padding: 0;
  margin: 0;

  background-color: var(--background);
  color: var(--text);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
select,
button {
  font: inherit;
  color: inherit;
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  font-weight: 600;
  line-height: 1.25;

  margin: 0;
}

h1 {
  font-weight: 900;
  line-height: 1;
}

p {
  margin: 0;
  line-height: 1.5;
}

hr {
  clear: both;
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
  background: var(--accent);
}

a {
  text-decoration: none;

  color: inherit;
}
