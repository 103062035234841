@import "./constants";

.collapse:empty {
  display: none;
}

@include responsive {
  .#{$bp}flex {
    display: flex;
  }
  .#{$bp}flex-row {
    flex-direction: row;
  }
  .#{$bp}flex-row-reverse {
    flex-direction: row-reverse;
  }
  .#{$bp}flex-col {
    flex-direction: column;
  }
  .#{$bp}flex-col-reverse {
    flex-direction: column-reverse;
  }

  .#{$bp}flex-grow {
    flex-grow: 1;
  }
  .#{$bp}flex-grow-0 {
    flex-grow: 0;
  }

  .#{$bp}flex-shrink {
    flex-shrink: 1;
  }
  .#{$bp}flex-shrink-0 {
    flex-shrink: 0;
  }

  .#{$bp}flex-wrap {
    flex-wrap: wrap;
  }
  .#{$bp}flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .#{$bp}flex-no-wrap {
    flex-wrap: nowrap;
  }
}
