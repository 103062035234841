.notifications {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;

  overflow-x: hidden;
  overflow-y: auto;

  pointer-events: none;
}
.notifications > * {
  pointer-events: initial;
}
