.route-container {
  &,
  & > * {
    height: 100vh;
  }
}

.success-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 1rem;

  pointer-events: none;
  opacity: 0;
  background-color: rgba(var(--success_rgb), 0.25);
  backdrop-filter: blur(0.5rem);

  transition: opacity;
  will-change: opacity;
}

.success-overlay--visible {
  pointer-events: auto;
  opacity: 1;
}

.auth__title {
  font-size: 2.25rem;

  @media (max-width: 30rem) {
    font-size: 7.5vw;
  }
}
