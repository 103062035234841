@import "./constants";

@each $label, $value in $numerical-sizes {
  .wrapping-box--#{$label} {
    flex-wrap: wrap;

    margin-right: -#{$value};
    margin-bottom: -#{$value};

    & > * {
      margin-right: #{$value};
      margin-bottom: #{$value};
    }
  }
}
