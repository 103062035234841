.flyout__item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  border: none;

  white-space: nowrap;
  text-align: left;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--card_shade);
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px var(--primary);
  }
}
