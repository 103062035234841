input[type="checkbox"] {
  appearance: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 1rem;
  height: 1rem;
  margin: 0;
  border: 1px solid var(--accent);
  border-radius: 0.25rem;

  background-color: var(--background);
  color: var(--background);

  transition: border 0.2s ease-out;

  &:focus {
    box-shadow: 0 0 0 4px rgba(var(--primary_rgb), 0.25);
  }

  &:disabled {
    background-color: var(--glow);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:checked {
    border: 0.5rem solid var(--primary);

    &::after {
      content: "";
      display: block;
      width: 0.3rem;
      height: 0.6rem;
      margin: -0.5rem;

      border-width: 0 0.15rem 0.15rem 0;
      border-style: solid;
      border-bottom-right-radius: 0.1rem;

      transform: translate(-0.05rem, -0.05rem) rotate(30deg);
    }
  }
}

input[type="radio"] {
  appearance: none;
  flex-shrink: 0;

  width: 1rem;
  height: 1rem;
  margin: 0;
  border: 1px solid var(--accent);
  border-radius: 2rem;

  background-color: var(--background);

  transition: border 0.2s ease-out;

  &:focus {
    box-shadow: 0 0 0 4px rgba(var(--primary_rgb), 0.25);
  }

  &:disabled {
    background-color: var(--glow);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:checked {
    border: 0.3rem solid var(--primary);

    &:disabled {
      border-color: var(--accent);
    }
  }
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.input__container {
  position: relative;
  flex-grow: 1;

  line-height: 1.5rem;

  background-color: var(--card);
  color: var(--caption);

  &:focus-within {
    z-index: 1;

    &,
    .input__label {
      color: var(--primary);
    }
  }
}

.input__container--disabled {
  background-color: var(--glow);
}

.input__container--contained {
  border: 1px solid var(--accent);
  border-radius: var(--border-radius);

  &:focus-within {
    border-color: var(--primary);
    box-shadow: 0 0 0 4px rgba(var(--primary_rgb), 0.25);
  }
}

.input__container--xs {
  padding: 0 0.5rem;
}
.input__container--sm {
  padding: 0.25rem 0.75rem;
}
.input__container--md {
  padding: 0.5rem 1rem;
}
.input__container--lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}
.input__container--xl {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
}

.input__container--bare {
  padding: 0;
  background-color: transparent;
  &:focus-within {
    box-shadow: 0 1px var(--primary);
  }
}

.input__inner {
  position: relative;
  display: flex;
  width: 100%;

  color: var(--caption);
}

.input__inner--filled {
  color: var(--text);
}

.input__asterix {
  line-height: 0;
  color: var(--error);
}

.input {
  position: relative;

  flex-grow: 1;
  display: block;
  width: 100%;
  min-height: 1.5rem;
  padding: 0;
  border: 0;

  color: var(--text);

  &::placeholder {
    color: var(--caption);
    opacity: 1;
  }

  &:required:placeholder-shown {
    background: url(../../images/asterix.svg) no-repeat top left 6.5rem / 0.4rem
      0.4rem;
  }

  &:focus {
    outline: none;
  }
}

.input__mask {
  flex-grow: 1;
  display: block;
  width: 100%;

  overflow: hidden;
  white-space: nowrap;
}

.input__mask--hidden.input__mask--hidden {
  pointer-events: none;
  margin: 0;
}

.input--hidden {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
}

.input--masked {
  position: relative;
  width: 100%;
  height: 1.5rem;
  border: none;

  opacity: 0;

  &:focus {
    opacity: 1;
    & + .input__mask--hidden {
      display: none;
    }
  }
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background: transparent;
  color: transparent;
}

.segmented-control {
  display: flex;
  align-items: stretch;
}

.segmented-control__segment {
  flex-grow: 1;
  width: 50%;
  padding: 0.5rem;

  box-shadow: inset 0 0 0 1px var(--accent);

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:focus-within {
    z-index: 1;
    border-color: var(--primary);
    box-shadow: inset 0 0 0 1px var(--primary),
      0 0 0 0.25rem rgba(var(--primary_rgb), 0.25);
  }
}

.segmented-control__segment--selected {
  background-color: var(--primary);
  color: var(--primary_contrast);
  box-shadow: inset 0 0 0 1px var(--primary_shade);
  z-index: 1;
}
