.multi-avatar {
  position: absolute;
  left: 0;
  top: 0;

  transform: scale(0);

  transition: transform 0.2s ease-in-out;
}

.multi-avatar--1\/1 {
  transform: scale(1);
}

.multi-avatar--1\/2 {
  transform: scale(0.75) translate(-42%, -42%);
}
.multi-avatar--2\/2 {
  transform: scale(0.75) translate(42%, 42%);
}

.multi-avatar--1\/3 {
  transform: scale(0.7) translate(0, -47%);
}
.multi-avatar--2\/3 {
  transform: scale(0.7) translate(58%, 47%);
}
.multi-avatar--3\/3 {
  transform: scale(0.7) translate(-58%, 47%);
}

.multi-avatar--1\/4 {
  transform: scale(0.6) translate(0, -80%);
}
.multi-avatar--2\/4 {
  transform: scale(0.6) translate(80%, 0);
}
.multi-avatar--3\/4 {
  transform: scale(0.6) translate(0, 80%);
}
.multi-avatar--4\/4 {
  transform: scale(0.6) translate(-80%, 0);
}
