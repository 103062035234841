.navbar {
  position: sticky;
  top: 0;
  z-index: 3;

  padding-top: env(safe-area-inset-top);
  border-bottom: 1px solid transparent;

  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  will-change: background-color, border-color, backdrop-filter;
}

.navbar--down {
  border-bottom-color: var(--accent);
}
