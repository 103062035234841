.toggle__container {
  cursor: pointer;

  flex-shrink: 0;
  position: relative;

  width: 2.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;

  background-color: var(--accent);

  transition: background-color 0.2s ease-in-out;

  &::after {
    content: "";

    position: absolute;
    left: 0.75rem;
    top: 0.75rem;

    width: 1.25rem;
    height: 1.25rem;
    border-radius: 9999px;

    background-color: var(--background);

    transform: translate(-50%, -50%);

    transition: transform 0.2s ease-in-out;
  }

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(var(--primary_rgb), 0.25);
  }
}

.toggle__container--checked {
  background-color: var(--primary);

  &::after {
    transform: translate(1rem, 0) translate(-50%, -50%);
  }
}

.toggle__container--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.toggle__input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
