.fade--horizontal {
  mask-image: linear-gradient(
    90deg,
    #0000,
    #000 1rem,
    #000 calc(100% - 1rem),
    #0000
  );
}

.tabs__container {
  position: sticky;
  top: env(safe-area-inset-top);
  z-index: 1;

  overflow-x: auto;

  display: flex;
  overflow-x: auto;
  padding: 1rem;

  mask-image: linear-gradient(
    90deg,
    #0000,
    #000 1rem,
    #000 calc(100% - 1rem),
    #0000
  );
  background: linear-gradient(var(--background) 60%, transparent);

  &::after {
    content: "";
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
  }
}

.tabs__container--card {
  background: linear-gradient(
    transparent 2.5rem,
    var(--card) 2.5rem,
    var(--card) 60%,
    transparent
  );
}

.simple-layout .tabs__container {
  padding-top: 3.5rem;
  margin-top: -2.5rem !important;
}

.tabs__tab {
  cursor: pointer;

  flex-shrink: 0;

  font-size: 2rem;
  line-height: 2.5rem;
  white-space: nowrap;

  font-weight: 900;

  color: var(--caption);

  transition: color 0.2s ease-in-out;

  &[data-partially-current="true"],
  &:first-child:last-child {
    color: var(--text);
  }

  &:focus {
    box-shadow: 0 1px var(--primary);
  }
}

.tabs__gradient {
  background: linear-gradient(
    var(--background),
    rgba(var(--background_rgb), 0.5) 75%,
    transparent
  );
}
