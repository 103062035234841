@import "../UI/constants";

@media (min-width: #{map-get($breakpoints, "md")}) {
  .debtor {
    width: calc(50% - 1rem);
  }
}

.transaction__parent {
  position: relative;
  z-index: 1;
}

.transaction__parent--receives::after {
  content: "";

  position: absolute;
  top: 1.75rem;
  left: 0.5rem;

  width: 0.5rem;
  height: 0.5rem;

  border-right: 2px solid #999;
  border-top: 2px solid #999;
  border-top-right-radius: 2px;

  transform: rotate(-45deg);
}

.transaction__child {
  position: relative;
  z-index: 0;
  padding-left: 2rem;

  &::before {
    content: "";

    position: absolute;
    left: calc(0.75rem - 1px);
    bottom: calc(50% - 1px);
    z-index: -1;

    width: 1rem;
    height: calc(100% + 0.5rem + var(--border-radius));
    border-left: 2px solid #999;
    border-bottom: 2px solid #999;
    border-bottom-left-radius: var(--border-radius);
  }
}

.transaction__child--receives::after {
  content: "";

  position: absolute;
  left: 1.2rem;
  top: 0.5rem;

  width: 0.5rem;
  height: 0.5rem;

  border-right: 2px solid #999;
  border-bottom: 2px solid #999;
  border-bottom-right-radius: 2px;

  transform: rotate(-45deg);
}

.transaction__parent + .transaction__child::before {
  height: calc(100% + var(--border-radius));
}
