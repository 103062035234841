.assignee-order--drag-over {
  background-color: var(--glow);
}

.assignee--dragged {
  margin-top: -1rem;
  margin-left: -1rem;
  @media (min-width: 42rem) {
    margin-left: calc(21rem - 50vw - 1rem);
  }
}

.assignee--idle {
  margin-top: 0 !important;
}

.drag-handle {
  position: relative;
  width: 0.5rem;
  height: 1rem;

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    width: 0.2rem;
    height: 0.2rem;
    border-radius: 100%;

    background-color: currentColor;
    color: var(--caption);

    box-shadow: 0.5rem 0, 0 0.5rem, 0.5rem 0.5rem, 0 1rem, 0.5rem 1rem;

    transform: translate(-50%, -50%);
  }
}
