@import "./constants";

.icon-btn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;

  line-height: 1em;

  border: 1px solid var(--accent);

  &:disabled {
    pointer-events: none;
    cursor: auto;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    border-color: var(--primary);
  }
}

.icon-btn--xs {
  padding: 0.25rem;
}
.icon-btn--sm {
  padding: 0.5rem;
}
.icon-btn--md {
  padding: 0.75rem;
}
.icon-btn--lg {
  padding: 1.25rem;
}
.icon-btn--xl {
  padding: 1.5rem;
}

.icon-link {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1em;
  border: none;

  color: var(--primary);
  background-color: transparent;

  &::before {
    content: "";
    border-radius: 9999px;
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    bottom: -0.25rem;
    right: -0.25rem;
  }

  &:hover {
    color: var(--primary_shade);
    &::before {
      background-color: var(--glow);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    outline: none;
    border-color: var(--primary);
  }

  &:focus::before {
    border: 1px solid var(--primary);
  }

  &::after {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    bottom: -0.5rem;
    right: -0.5rem;
  }
}

@each $color in $variable-colors {
  .icon-btn--#{$color} {
    background-color: var(--#{$color});
    color: var(--#{$color}_contrast);

    &:hover {
      background-color: var(--#{$color}_shade);
    }
  }

  .icon-link--#{$color} {
    color: var(--#{$color});

    &:hover {
      color: var(--#{$color}_shade);
    }
  }
}
