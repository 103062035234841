.user__child {
  position: relative;

  padding-top: 1rem;
  padding-left: 2.5rem;

  &::before {
    content: "";

    position: absolute;
    left: calc(1.25rem - 1px);
    bottom: calc(50% - 0.5rem - 1px);
    z-index: -1;

    width: 1.5rem;
    height: calc(100% + var(--border-radius));
    border-left: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    border-bottom-left-radius: var(--border-radius);
  }
}
