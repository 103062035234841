.image-input {
  position: relative;
  border: 1px solid var(--accent);
  background: var(--glow) no-repeat center / cover;
  background-clip: padding-box;

  &:focus-within {
    border-color: var(--primary);
    box-shadow: 0 0 0 4px rgba(var(--primary_rgb), 0.25);
  }
}

.image-input--textured {
  background: var(--glow) url("../../images/food-texture.svg") repeat center;
}

.image-input--sm {
  background-size: 3rem;
}

.image-input__label {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}
