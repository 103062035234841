.day-toggle {
  cursor: pointer;

  width: 2rem;
  height: 2rem;
  border-radius: 100%;

  line-height: 2rem;
  text-align: center;

  background-color: var(--glow);
  color: var(--text);
}

.day-toggle--checked {
  background-color: var(--primary);
  color: var(--background);
}
