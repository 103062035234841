@import "./constants";

.rounded-none {
  border-radius: 0;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}
.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.rounded-tl-none {
  border-top-left-radius: 0;
}
.rounded-tr-none {
  border-top-right-radius: 0;
}
.rounded-br-none {
  border-bottom-right-radius: 0;
}
.rounded-bl-none {
  border-bottom-left-radius: 0;
}
.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}
.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}
.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}
.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}
.rounded-tl {
  border-top-left-radius: 0.25rem;
}
.rounded-tr {
  border-top-right-radius: 0.25rem;
}
.rounded-br {
  border-bottom-right-radius: 0.25rem;
}
.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}
.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}
.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}
.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}
.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}
.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-tl-full {
  border-top-left-radius: 9999px;
}
.rounded-tr-full {
  border-top-right-radius: 9999px;
}
.rounded-br-full {
  border-bottom-right-radius: 9999px;
}
.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0;
}
.border-2 {
  border-width: 2px;
}
.border-4 {
  border-width: 4px;
}
.border-8 {
  border-width: 8px;
}
.border-t {
  border-top-width: 1px;
}
.border-r {
  border-right-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.border-t-0 {
  border-top-width: 0;
}
.border-r-0 {
  border-right-width: 0;
}
.border-b-0 {
  border-bottom-width: 0;
}
.border-l-0 {
  border-left-width: 0;
}
.border-t-2 {
  border-top-width: 2px;
}
.border-r-2 {
  border-right-width: 2px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-t-4 {
  border-top-width: 4px;
}
.border-r-4 {
  border-right-width: 4px;
}
.border-b-4 {
  border-bottom-width: 4px;
}
.border-l-4 {
  border-left-width: 4px;
}
.border-t-8 {
  border-top-width: 8px;
}
.border-r-8 {
  border-right-width: 8px;
}
.border-b-8 {
  border-bottom-width: 8px;
}
.border-l-8 {
  border-left-width: 8px;
}

.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-double {
  border-style: double;
}
.border-none {
  border-style: none;
}

.divide-x-0 > * + * {
  border-left-width: 0;
  border-left-style: solid;
}
.divide-x-2 > * + * {
  border-left-width: 2px;
  border-left-style: solid;
}
.divide-x-4 > * + * {
  border-left-width: 4px;
  border-left-style: solid;
}
.divide-x-8 > * + * {
  border-left-width: 8px;
  border-left-style: solid;
}
.divide-x > * + * {
  border-left-width: 1px;
  border-left-style: solid;
}
.divide-y-0 > * + * {
  border-top-width: 0;
  border-top-style: solid;
}
.divide-y-2 > * + * {
  border-top-width: 2px;
  border-top-style: solid;
}
.divide-y-4 > * + * {
  border-top-width: 4px;
  border-top-style: solid;
}
.divide-y-8 > * + * {
  border-top-width: 8px;
  border-top-style: solid;
}
.divide-y > * + * {
  border-top-width: 1px;
  border-top-style: solid;
}

@each $color in $variable-colors {
  .border-#{$color} {
    border-color: var(--#{$color});
  }

  .divide-#{$color} > * + * {
    border-color: var(--#{$color});
  }
}
