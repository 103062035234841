$variable-sizes: "xs", "sm", "md", "lg", "xl";

@each $size in $variable-sizes {
  .shadow-#{$size} {
    box-shadow: var(--box-shadow-#{$size});
  }
}

.glass {
  backdrop-filter: blur(0.5rem);
  background-color: rgba(var(--card_rgb), 0.1);
}

.opacity-0 {
  opacity: 0;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-100 {
  opacity: 1;
}

.fade-r {
  mask: linear-gradient(90deg, #000 calc(100% - 1rem), #0000);
}

.cutout-rb {
  mask: radial-gradient(
    circle at 1.625rem 1.625rem,
    #0000 0.5rem,
    #000 0.55rem
  );
}
